.service-card {
    background-color:#333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgb(255, 208, 0);
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s ease;
    color:white;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-image {
    width: 250px;
    height: 150px;
    border-radius: 1rem;
    margin: 1rem auto ;
    display: block;
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
   
  }
  
  .service-card p {
    font-size: 1rem;
    margin: 0.5rem 0; 
  }
  
  .service-price  {
    font-weight: bold;
    color:white;
    
  }
  
 @media (max-width: 768px) {
  .service-card {
    width: 298px;
    margin: 1rem;
    text-align: center;
  }

  
 } 

 @media (max-width : 1024px){
  .service-image{
    width:265px;
    margin: 1rem 1rem;
  }
 }
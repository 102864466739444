/* src/pages/Home.css */
.home-container {
  padding: 2rem;
}

.recent-work {
  text-align: center;
  margin: 2rem 0;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  
}

.recent-work h2 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  text-shadow: 3px 0px 3px #1CA3DC;
}

.work-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.work-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.work-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.work-item:hover img {
  transform: scale(1.05);
}

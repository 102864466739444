/* src/pages/About.css */
.main{
  
  background-image: url('../assets/images/bg1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  margin-top:0px;
  margin-bottom: -20px;
    
}
.about-content {
  
    max-width: 800px;
    margin: 0 auto;
    padding: 0rem 1rem;
    padding-bottom: 0rem;
    background-color: #1a1a1a;
    opacity: 0.8;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "Noto Sans", sans-serif;
  }

  .about-content h1{
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 2.5rem;
    text-align: center;
    padding: 1rem 0rem;
    color:white;
    text-shadow: 3px 0px 3px #1CA3DC;
  }
  
   .about-content h2 {
   
    text-align: center;
    color: #f0a500;
  }
  
  .about-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .about-services ul {
    list-style-type: none;
    padding: 0;
  }
  
  .about-services li {
    background-color: #333;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
  }
  
  .about-services li::before {
    content: '✓';
    margin-right: 10px;
    color: #f0a500;
  }
  
  @media (max-width: 1024px) {
    .main{
     background-size:cover; /* or 'cover', depending on the effect you want */
     background-position: center center;
     height: 79rem;
    } 
    
    .about-content{
     padding: 1rem;
     margin-top: 40px;
    }
 
    .about-content p{
     font-size: 1.3rem;
    }
 
    .about-services li{
     font-size: 1.3rem;
    }
   }

  @media (max-width: 768px) {
    .about-content{
      padding: 0 1rem;
      max-width:335px;
    }
    .about-content p{
      font-size: 1rem;
     }
  }

  
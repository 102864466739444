.services-container {
  padding: 2rem;
  text-align: center;
  
}

.services-container h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 2.5rem;
    text-align: center;
    color:white;
    text-shadow: 3px 0px 3px #1CA3DC;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  font-family: Calibri;
}

/* src/pages/Contact.css */
.contact-container {
  background: url('../assets/images/contbg.png') no-repeat center center fixed; /* Background image path */
  background-size: cover;
  color: white; /* Text color for readability */
  padding: 2rem;
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column; /* Arrange items vertically */
}

.contact-main {
  display: flex;
  justify-content: space-between; /* Space between company-info and contact-form */
  gap: 2rem; /* Space between columns */
  margin-bottom: 2rem;
}

.company-info {
  flex: 1; /* Take up equal space */
  text-align: left; /* Align text to the left */
  margin-bottom: 2rem;
  color: #e3f2fd;
  text-shadow: 2px 2px 4px black; /* Slightly adjusted text-shadow for better readability */
}

.company-info h1 {
  font-size: 2.5rem; /* Adjust font size */
  margin-bottom: 1rem;
  text-align: center;
}

.company-info p {
  font-size: 1.2rem; /* Slightly smaller font size for better readability */
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  font-weight: bold;
}

.contact-form {
  flex: 1; /* Take up equal space */
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px; /* Max width of the form */
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
}

.contact-form h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem; /* Slightly increased padding */
  margin-bottom: 1rem;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
}

.contact-form button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #f0a500;
  color: white;
  font-size: 1.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #d18e00;
}

.map-container {
  margin-top: 2rem;
  font-family: "Montserrat", sans-serif;
  text-shadow: 2px 2px 4px black;
}

.map-container iframe{
  border-radius: 8px;
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 1024px) {
  .contact-main {
    flex-direction: column; /* Stack vertically on tablets */
  }
  
  .company-info,
  .contact-form {
    width: 100%; /* Ensure both sections take full width */
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .company-info {
    font-size: 1rem; /* Reduce font size for mobile */
  }

  .company-info h1 {
    font-size: 2rem; /* Adjust heading size */
    text-align: center;
  }

  .company-info p {
    font-size: 0.9rem; /* Adjust paragraph size */
  }

  .contact-form {
    padding: 0.5rem ; /* Reduce padding for smaller screens */
    margin: 0rem -0.5rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 0.5rem 0 ; /* Increase padding for better touch interaction */
  }

  .contact-form button {
    padding: 0.5rem; /* Adjust button padding */
  }
  
  .map-container iframe {
    height: 300px; /* Adjust iframe height for mobile */
  }
}

/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 0.2rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-logo {
  flex: 0.1;
}

.navbar-logo img {
  height: 50px;  /* Adjust the height as needed */
  width: auto;
}

.navbar-title {
  flex: 2;
  font-size: 1.5rem;
  font-weight: bolder;
  text-align:left;

}

.navbar-title a {
  color: #1CA3DC;
  font-size: 5rem;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  text-decoration: none;
  font-size: 2.5rem;  /* Adjust font size as needed */
  -webkit-text-stroke: 1px #fbfafa;
}

.navbar-links {
  flex: 1;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 1rem;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: "Varela Round", sans-serif;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #1CA3DC;
}

.icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  .navbar-links {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    background-color: #333;
    border-radius: 5px;
    top: -400px;
    right: 0;
    width: 30%;
    text-align: center;
  }

  .navbar-links.active {
    top: 65px;  /* Adjust this value to ensure it aligns under the navbar */
    transition: top 0.3s ease-in;
  }

  .navbar-links li {
    margin: 10px 0;
  }

  .icon {
    display: block;
  }

  .navbar-title a{
    font-size: 1.5rem;
  }
  
}

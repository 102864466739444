/* Footer Styles */
footer {
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  font-family: "Roboto Condensed", sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 2rem;
}

.service-container,
.comp-info,
.map-containers,
.social-media {
  flex: 1;
  text-align: left;
}

.service-link a {
  text-decoration: none;
  color: inherit;
}

.service-link :hover{
  color: #78a7ef;
}
.service-container ul
{
  list-style-type: none;
  padding: 0;
}

.map-containers iframe {
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  height: 250px;
}

/* Social Media Icons */
.social-media {
  display: flex;
  flex-direction: column; /* Keep heading and icons vertically aligned */
  align-items: center; /* Center align the content */
  gap: 10px;
  margin: 1rem 0;
}

.social-media h3 {
  margin: 0; /* Remove default margin from the heading */
  font-size: 1.5rem; /* Adjust the font size */
}

.social-media .icon-container {
  display: flex; /* Align icons horizontally */
  gap: 1rem; /* Space between icons */
}

.social-media a {
  color: white; /* Set default color for the icons */
  font-size: 2rem; /* Adjust the icon size */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.social-media a:hover {
  color: #007bff; /* Change color on hover */
}

/* Copyright Section */
.copy-right {
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  border-top: 1px solid #555;
  padding-top: 1rem;
}




/* Responsive Styles */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .service-container,
  .comp-info,
  .map-containers {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .service-container,
  .comp-info,
  .map-containers {
    text-align: center;
  }

  .map-containers iframe {
    width: 100%;
    height: 200px;
  }
}
